import { useCallback, useState } from "react";

import { AppArticleResourcePlayerDto } from "dto/components/app-article-info/app-article-dto";

import AppPagination from "components/app-pagination";

const AppArticleResourcePlayer = ({ resources }: AppArticleResourcePlayerDto) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const onHandlePlayNextVideo = useCallback(() => {
		if (resources && currentIndex < resources.length - 1) {
			setCurrentIndex((prev) => prev + 1);
		} else {
			setCurrentIndex(0);
		}
	}, [currentIndex, resources]);

	const onHandlePlayBackVideo = useCallback(() => {
		if (currentIndex !== 0) {
			setCurrentIndex((prev) => --prev);
		} else {
			setCurrentIndex(resources.length - 1);
		}
	}, [currentIndex, resources.length]);

	return (
		<div className="app-article-resource-player">
			<div className="article-resource-player">
				{resources.map((item, index) => {
					if (currentIndex !== index) return null;

					return (
						<div key={index} className="article-resource-player__item">
							<img className="image" src={item} alt="" />
						</div>
					);
				})}

				<AppPagination className="app-pagination" totalPageNumber={resources.length} currentPage={currentIndex} onHandleNext={onHandlePlayNextVideo} onHandleBack={onHandlePlayBackVideo} />
			</div>
		</div>
	);
};

export default AppArticleResourcePlayer;
