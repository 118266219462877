// library
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// pathnames
import pathnames from "routes/pathnames";

// services
import api from "services/api";

// hooks
import useTemplateContext from "hooks/use-template-context";

// common
// import classNames from "common/class-names";
import serveRequestErrors from "common/serve-request-errors";

// dto
import ErrorResponseDto from "dto/services/error-response-dto";
import { ArticleDto, FaqDto, PartnerListDto, ServiceDto, ProsDto } from "dto/pages/page-home-dto";

// component
import AppNavbar from "components/app-navbar";
import AppFooter from "components/app-footer";
import AppButton from "components/app-button";
import AppNewsCard from "components/app-news-card";
import AppSlideShow from "components/app-slideshow";
// import AppPagination from "components/app-pagination";
import AppArticleCard from "components/app-article-card";
import AppFaqCard from "components/pages/page-home/app-faq-card";
import AppCompanyCard from "components/pages/page-home/app-company-card";
import AppServiceSolutionCard from "components/pages/page-home/app-service-solution-card";

const PageHome = () => {
	const navigate = useNavigate();
	const queryParams = useRef({ search: "", page: 0, size: 9 });
	/* Hide Our Client section temporary, until BA team comes out with contents 
	const sliderRef = useRef<HTMLDivElement | null>(null);
	const [videoIndex, setVideoIndex] = useState<number>(0);
	const [videoKey, setVideoKey] = useState(Date.now());
	const [clientsData, setClientsData] = useState<ClientDto[]>([]);
    */
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isVideoReady, setIsVideoReady] = useState(false);
	const [main1Services, setMain1Services] = useState<ServiceDto[]>([]);
	const [main2Services, setMain2Services] = useState<ServiceDto[]>([]);
	const [partnerList, setPartnerList] = useState<PartnerListDto[]>([]);
	const [faqData, setFaqData] = useState<FaqDto[]>([]);
	const [reorderedFaqData, setReorderedFaqData] = useState<FaqDto[]>([]);
	const [articleData, setArticleData] = useState<ArticleDto[]>([]);
	const [headerClassName, setHeaderClassName] = useState("app-navbar__fixed");
	const { getFilteredResources } = useTemplateContext();

	const homePage = useMemo(() => getFilteredResources("page.landing"), [getFilteredResources]);
	const getPageData = useCallback((key: string) => homePage?.find((value) => value.key === key)?.value?.toString() ?? "", [homePage]);
	const coverImage = useMemo(() => getPageData("page.landing.cover.video"), [getPageData]);
	const partnerHeader = useMemo(() => getPageData("page.landing.partner.header"), [getPageData]);
	const serviceHeader = useMemo(() => getPageData("page.landing.service.header"), [getPageData]);
	const serviceDescription = useMemo(() => getPageData("page.landing.service.description"), [getPageData]);
	const serviceSubHeader = useMemo(() => getPageData("page.landing.service.subHeader"), [getPageData]);
	const serviceSubHeaderPartner = useMemo(() => getPageData("page.landing.service.subHeaderPartner"), [getPageData]);
	const prosHeader = useMemo(() => getPageData("page.landing.pros.header"), [getPageData]);
	// const clientHeader = useMemo(() => getPageData("page.landing.client.header"), [getPageData]);
	// const clientViewLabel = useMemo(() => getPageData("page.landing.client.view.label"), [getPageData]);
	const serviceViewLabel = useMemo(() => getPageData("page.landing.service.view.label"), [getPageData]);
	const articleHeader = useMemo(() => getPageData("page.landing.article.header"), [getPageData]);
	const articleViewLabel = useMemo(() => getPageData("page.landing.article.view.label"), [getPageData]);
	const faqHeader = useMemo(() => getPageData("page.landing.faq.header"), [getPageData]);
	const faqDescription = useMemo(() => getPageData("page.landing.faq.description"), [getPageData]);
	const coverTitle = useMemo(() => getPageData("page.landing.catch.phrase"), [getPageData]);
	const splitCoverTitle = useMemo(() => (Boolean(coverTitle) ? coverTitle.split("<position>") : []), [coverTitle]);
	const coverPosition = useMemo(() => (homePage?.find((value) => value.key === "page.landing.job.position")?.value as string[]) || [], [homePage]);
	const prosData = useMemo(() => (homePage?.find((value) => value.key === "page.landing.pros")?.value as ProsDto[]) || [], [homePage]);

	const onHandleGetPartnerList = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.partner.partner();

			setPartnerList(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	const onHandleGetServices = useCallback(async () => {
		try {
			const params = queryParams.current;

			const payload = { size: 9, page: params.page };

			const [responseMain1, responseMain2] = await Promise.all([api.get.services.services("main1", payload), api.get.services.services("main2", payload)]);

			setMain1Services(responseMain1.data.data.list.content);

			setMain2Services(responseMain2.data.data.list.content);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, []);

	/* Hide Our Client section temporary, until BA team comes out with contents 
	const onHandleGetClientData = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.clients.clients();

			const clientsList = response.data.data.list.map((client: ClientDto) => ({
				...client,
				mediaType: client.cover.mediaType || (client.cover ? "video" : "image"),
				mediaUrl: client.cover.coverPath || client.thumbnail,
			}));

			setClientsData(clientsList);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;
			serveRequestErrors(err);
		}
	}, []);

    const onHandlePlayBackVideo = useCallback(() => {
		if (videoIndex !== 0) {
			setVideoIndex((prev) => --prev);

			setVideoKey(Date.now());
		}
	}, [videoIndex]);

	const onHandlePlayNextVideo = useCallback(() => {
		if (videoIndex !== clientsData.length - 1) {
			setVideoIndex((prev) => ++prev);

			setVideoKey(Date.now());
		}
	}, [videoIndex, clientsData.length]);

	const onHandleSwitchVideo = useCallback((index: number) => {
		setVideoIndex(index);

		setVideoKey(Date.now());
	}, []);
    
    const getYouTubeEmbedUrl = (url?: string) => {
		let videoId = undefined;

		if (!url) return undefined;

		if (url.includes("v=")) {
			videoId = url.split("v=")[1]?.split("&")[0];
		} else if (url.includes("youtu.be/")) {
			videoId = url.split("youtu.be/")[1];
		}

		if (!videoId) {
			return undefined;
		}

		return `https://www.youtube-nocookie.com/embed/${videoId}?disablekb=1&controls=0&showinfo=0&modestbranding=0&rel=0&playsinline=1&enablejsapi=1&&autoplay=1&loop=1&mute=1&playlist=${videoId}`;
	};

    const navigateToProjectShowcase = (projectId: string) => {
		navigate(`${pathnames.PageProjectShowCase}/${projectId}`);
	};

	const scrollSliderBy = useCallback(
		(direction: "left" | "right") => {
			if (!sliderRef.current) return;
			const itemWidth = 581;
			const isStart = videoIndex === 0;
			const isEnd = videoIndex === clientsData.length - 1;

			if (direction === "right" && !isEnd) {
				sliderRef.current.scrollBy({ left: itemWidth, behavior: "smooth" });
			} else if (direction === "left" && !isStart) {
				sliderRef.current.scrollBy({ left: -itemWidth, behavior: "smooth" });
			}
		},
		[clientsData.length, videoIndex]
	);

	
	const onHandleNext = useCallback(() => {
		scrollSliderBy("right");
		onHandlePlayNextVideo();
	}, [onHandlePlayNextVideo, scrollSliderBy]);

	const onHandleBack = useCallback(() => {
		scrollSliderBy("left");
		onHandlePlayBackVideo();
	}, [onHandlePlayBackVideo, scrollSliderBy]);
    */

	const getReorderedFaqData = useCallback((data: any[]) => {
		if (window.innerWidth <= 991) {
			return setReorderedFaqData(data);
		}
		const rows = Math.ceil(data.length / 2);
		const reordered = new Array(data.length);

		data.forEach((item, index) => {
			const col = Math.floor(index / rows);
			const row = index % rows;
			reordered[row * 2 + col] = item;
		});
		setReorderedFaqData(reordered);
	}, []);

	const onHandleGetFaq = useCallback(async () => {
		let response = null;

		try {
			response = await api.get.faq.faq();
			setFaqData(response.data.data.list);
			getReorderedFaqData(response.data.data.list);
		} catch (error) {
			const err = error as Error | ErrorResponseDto;

			serveRequestErrors(err);
		}
	}, [getReorderedFaqData]);

	const onHandleOurArticles = useCallback(async () => {
		let response = null;

		try {
			const params = queryParams.current;

			const payload = { size: 3, page: params.page, param: params.search };

			response = await api.get.ourArticles.latestArticles(payload);

			setArticleData(response.data.data.list.content);
		} catch (unknown: unknown) {
			const error = unknown as Error | ErrorResponseDto;

			serveRequestErrors(error);
		}
	}, []);

	const navigateToServices = () => {
		navigate(pathnames.PageAllProducts);
	};

	useEffect(() => {
		// onHandleGetClientData();
		onHandleGetFaq();
		onHandleOurArticles();
		onHandleGetPartnerList();
		onHandleGetServices();
	}, [onHandleGetFaq, onHandleGetPartnerList, onHandleGetServices, onHandleOurArticles]);

	useEffect(() => {
		const isFixedHeader = () => {
			const scrolledTo = window.scrollY;

			if (scrolledTo >= 120) setHeaderClassName("app-navbar__fixed--active");
			else setHeaderClassName("app-navbar__fixed");
		};

		window.addEventListener("scroll", isFixedHeader);

		return () => window.removeEventListener("scroll", isFixedHeader);
	}, []);

	useEffect(() => {
		if (coverPosition.length > 0 && isVideoReady) {
			setTimeout(() => {
				const el = document.querySelector(".text");
				const fx = new TextScramble(el);

				let counter = 0;
				const next = () => {
					fx.setText(coverPosition[counter]).then(() => {
						setTimeout(next, 800);
					});
					counter = (counter + 1) % coverPosition.length;
				};

				next();
			}, 1000);
		}
	}, [coverPosition, isVideoReady]);

	useEffect(() => {
		const videoElement = videoRef.current;
		if (!videoElement) return;

		const handleCanPlay = () => {
			setIsVideoReady(true);
			videoElement.play().catch((error) => {
				console.error("Autoplay was prevented:", error);
			});
		};

		const handleError = (error: ErrorEvent) => {
			console.error("Video loading error:", error);
			setIsVideoReady(false);
		};

		videoElement.addEventListener("canplay", handleCanPlay);
		videoElement.addEventListener("error", handleError);

		// Attempt to play on load
		videoElement.load();

		return () => {
			videoElement.removeEventListener("canplay", handleCanPlay);
			videoElement.removeEventListener("error", handleError);
		};
	}, [coverImage]);

	useEffect(() => {
		const handleResize = () => getReorderedFaqData(faqData);

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [faqData, getReorderedFaqData]);

	return (
		<div className="page-home">
			<div className="home">
				<AppNavbar className={headerClassName} />

				<div className="home-container">
					<div className="home__height">
						<video ref={videoRef} src={coverImage} className="home__bg-video" autoPlay muted loop controls={false} playsInline></video>
					</div>

					<div className="home-wrapper">
						<div className="context">
							<div className="context__text">
								{isVideoReady && (
									<div className="context__text__pre__wrapper">
										{splitCoverTitle[0].split(" ", 5).map((text, index) => (
											<span key={index} className="context__text__pre">
												{text}
											</span>
										))}
									</div>
								)}
								<span className="content__container">
									<div className="text"></div>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div className="partners-section">
					<p className="partners-section__title" id="animation-3">
						{partnerHeader}
					</p>

					{partnerList.length > 0 && (
						<AppSlideShow>
							{partnerList.map((item, index) => (
								<div key={index}>
									<img className="marquee-logo__img" src={item.logo} alt="" width="142" height="80" />
								</div>
							))}
						</AppSlideShow>
					)}
				</div>

				<div className="services-section">
					<p className="services-section__title">{serviceHeader}</p>

					<div className="services-section__description">
						{serviceDescription.split("<br>").map((line, index) => (
							<p key={index}>{line}</p>
						))}
					</div>

					<p className="services-section__subtitle">{serviceSubHeader}</p>

					<div className="services-section__wrapper">
						{main1Services.map((o) => (
							<AppServiceSolutionCard key={o.id} title={o.title} description={o.description} />
						))}
					</div>

					<p className="services-section__subtitle">{serviceSubHeaderPartner}</p>

					<div className="services-section__wrapper">
						{main2Services.map((o) => (
							<AppServiceSolutionCard key={o.id} title={o.title} description={o.description} />
						))}
					</div>

					{serviceViewLabel && (
						<div className="services-section__button-container">
							<AppButton label={serviceViewLabel} onClick={navigateToServices} />
						</div>
					)}
				</div>

				<div className="company-intro">
					<p className="company-intro__title">{prosHeader}</p>

					<div className="company-intro__wrapper">
						{prosData?.length > 0 && prosData.map((o, i) => <AppCompanyCard key={i} description={o.description} number={o.number} workforce={o.workforce} description1={o.description1} />)}
					</div>
				</div>

				{/* Hide Our Client section temporary, until BA team comes out with contents */}
				{/* {clientsData.length > 0 && (
					<div className="clients-section">
						<div className="clients-section__body">
							<div className="clients-section__wrapper">
								<div>
									<p className="clients-section__title">{clientHeader}</p>

									<div key={clientsData[videoIndex].id}>
										<p className="clients-section__sub-title">{clientsData[videoIndex].title}</p>

										<p className="clients-section__description">{clientsData[videoIndex].information}</p>

										{clientViewLabel && <AppButton label={clientViewLabel} onClick={() => navigateToProjectShowcase(clientsData[videoIndex].id)} secondary uppercase />}
									</div>
								</div>

								<AppPagination totalPageNumber={clientsData.length} currentPage={videoIndex} onHandleNext={onHandleNext} onHandleBack={onHandleBack}/>
							</div>

							<div className="clients-section__container">
								<div className="clients-section__slider-container">
									<div className="slider" ref={sliderRef}>
										{clientsData.map((o, i) => {
											const imageClassName = classNames({ slider__image: true, "slider__image--active": i === videoIndex });
											return (
												<div className="slider__item" key={i} onClick={() => onHandleSwitchVideo(i)}>
													<div className={imageClassName} style={{ backgroundImage: `url(${o.thumbnail})` }} />

													<div className="slider__wrapper">
														<p className="slider__title">{o.title}</p>

														<p className="slider__description">{o.information}</p>

														<p className="slider__text">{o.platform}</p>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>

							<div className="clients-section__youtube">
								{clientsData[videoIndex].cover.mediaType === "VIDEO" ? (
									<iframe
										key={videoKey}
										width="100%"
										height="720px"
										src={getYouTubeEmbedUrl(clientsData[videoIndex].cover.coverPath)}
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										title={`YouTube video ${videoIndex}`}
									/>
								) : (
									<img className="clients-section__image" src={clientsData[videoIndex].cover.coverPath} alt={clientsData[videoIndex].title} style={{ width: "100%", height: "auto" }} />
								)}
							</div>
						</div>
					</div>
				)} */}

				<div className="articles-section">
					<div className="articles-section__header">
						<p className="articles-section__title">{articleHeader}</p>

						{articleViewLabel && <AppButton label={articleViewLabel} onClick={() => navigate(pathnames.PageAllArticles)} tertiary />}
					</div>

					<div className="articles-section__wrapper">
						{articleData.map((o, i) => {
							const articleLink = `${pathnames.PageArticle}/${o.id}`;

							return <AppArticleCard title={o.title} description={o.category} image={o.thumbnail} date={o.date} location={o.location} key={i} link={articleLink} />;
						})}
					</div>
				</div>

				<div className="faq-section">
					<p className="faq-section__title">{faqHeader}</p>

					{faqDescription && <p className="faq-section__description">{faqDescription}</p>}

					<div className="faq-section__container">
						{reorderedFaqData.map((o, i) => {
							return <AppFaqCard question={o.question} answer={o.answer} index={(o.sortNumber || i) - 1} key={i} />;
						})}
					</div>
				</div>

				<AppNewsCard />

				<AppFooter />
			</div>
		</div>
	);
};

class TextScramble {
	private el: HTMLElement | any;
	private chars: string = "!<>-_\\/[]{}—=+*^?#________";
	private queue: Array<{
		from: string;
		to: string;
		start: number;
		end: number;
		char?: string;
	}> = [];
	private frame: number = 0;
	private frameRequest?: number;
	private resolve?: () => void;

	constructor(el: any) {
		this.el = el;
		this.update = this.update.bind(this);
	}

	setText(newText: string): Promise<void> {
		const oldText = this.el.innerText;
		const length = Math.max(oldText.length, newText.length);
		const promise = new Promise<void>((resolve) => {
			this.resolve = resolve;
		});

		this.queue = [];
		for (let i = 0; i < length; i++) {
			const from = oldText[i] || "";
			const to = newText[i] || "";
			const start = Math.floor(Math.random() * 40);
			const end = start + Math.floor(Math.random() * 40);
			this.queue.push({ from, to, start, end });
		}

		cancelAnimationFrame(this.frameRequest!);
		this.frame = 0;
		this.update();
		return promise;
	}

	update() {
		let output = "";
		let complete = 0;
		for (let i = 0, n = this.queue.length; i < n; i++) {
			let { from, to, start, end, char } = this.queue[i];
			if (this.frame >= end) {
				complete++;
				output += to;
			} else if (this.frame >= start) {
				if (!char || Math.random() < 0.28) {
					char = this.randomChar();
					this.queue[i].char = char;
				}
				output += `<span class="dud">${char}</span>`;
			} else {
				output += from;
			}
		}

		this.el.innerHTML = output;
		if (complete === this.queue.length) {
			this.resolve?.();
		} else {
			this.frameRequest = requestAnimationFrame(this.update);
			this.frame++;
		}
	}

	randomChar() {
		return this.chars[Math.floor(Math.random() * this.chars.length)];
	}
}

export default PageHome;
