import { memo } from "react";

const AppScreenLoader = () => {
	return (
		<div className="app-screen-loader">
			<div className="screen-loader">
				<svg viewBox="0 0 600 300">
					<symbol id="s-text">
						<text textAnchor="middle" x="50%" y="50%" dy=".35em">
							ALTECFLEX
						</text>
					</symbol>
					<use className="screen-loader__text" xlinkHref="#s-text"></use>
					<use className="screen-loader__text" xlinkHref="#s-text"></use>
					<use className="screen-loader__text" xlinkHref="#s-text"></use>
					<use className="screen-loader__text" xlinkHref="#s-text"></use>
					<use className="screen-loader__text" xlinkHref="#s-text"></use>
				</svg>
			</div>
		</div>
	);
};

export default memo(AppScreenLoader);
