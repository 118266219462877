import { AppArticleInfoDto } from "dto/components/app-article-info/app-article-dto";

const AppArticleInfo = ({ publicationDate, category, tableOfContent, location }: AppArticleInfoDto) => {
	return (
		<div className="app-article-info">
			<div className="article-info">
				<div className="article-info__header">
					<div className="article-info__block">
						<p className="article-info__title">Publication Date</p>
						<p className="article-info__description">{publicationDate}</p>
					</div>

					<div className="article-info__block">
						<p className="article-info__title">Category</p>
						<p className="article-info__description">{category}</p>
					</div>
				</div>

				<div className="article-info__subheader">
					<div className="article-info__section">
						<p className="article-info__title">Location</p>
						<p className="article-info__description">{location}</p>
					</div>
				</div>

				<div className="article-info__content">
					<p className="article-info__title">Table of Contents</p>
					<ul className="article-info__description">
						{tableOfContent?.map((desc: string, index: number) => (
							<li key={index}>{desc}</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};
export default AppArticleInfo;
